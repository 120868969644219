import React, { FC } from 'react';
import { Col, Row } from 'antd';
import MainLogo from '../../assets/svg/MianLogo'
import {AudioPlayerProvider, useAudioPlayer, useAudioPosition} from 'react-use-audio-player';
import PlayAnime from '../../assets/img/PlayAnime3.gif';
import PauseImg from '../../assets/img/Pause2.png';
const Radio: FC =() => {
    return(
        <>
        <Row className='background' style={{height:'100vh'}}>
            <Col xs={0} sm={4} md={6} lg={8} xl={8}>
            
            </Col>
            <Col xs={24} sm={16} md={12} lg={8} xl={8}>
                <AudioPlayerProvider>
                    <RadioPnl/>
                </AudioPlayerProvider>
            </Col>
            <Col xs={0} sm={4} md={6} lg={8} xl={8}>
            
            </Col>
        </Row>
        </>
    );
}

export default Radio


const RadioPnl = () => {
    const {togglePlayPause, ready, loading, playing} = useAudioPlayer({
        src: 'https://stream.insharp.life/listen.mp3',
        autoplay: true,
        html5: true,
        format: ['mp3'],
        onend: () => console.log('sound has ended!')
    });
    return(
    <>
        <div className="oval-back"></div>
        <Row>
            <Col span={24} style={{display:'flex', justifyContent:'center', marginTop:'100px'}}>
                <MainLogo/>
            </Col>
        </Row>
        <Row>
            <Col span={24} style={{display:'flex', justifyContent:'center'}}>
                <h2>Now on Air !</h2>
            </Col>
        </Row>
        <Row style={{marginTop:'50vh'}}>
            <Col span={8} style={{display:'flex', justifyContent:'center'}}>
            {ready ? (
                // eslint-disable-line no-use-before-define
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                <img
                    src={playing ? PlayAnime : PauseImg}
                    alt={'banner'}
                    style={{width: '80px', height: '80px', cursor: 'pointer'}}
                    onClick={togglePlayPause}
                />
            ) : (
                <p>
                    Loading...
                </p>
            )}{' '}
            </Col>
            <Col span={8} style={{display:'flex', justifyContent:'center'}}>
            <h3
                className={'tap-text'}
                //style={{marginLeft: '20px', marginRight: '100px', cursor: 'pointer', width: '100px'}}
                onClick={togglePlayPause}
            >
                {playing ? 'Tap to pause' : 'Tap to play'}
            </h3>{' '}
            </Col>
            <Col span={8} style={{display:'flex', justifyContent:'center'}}>
                <h2></h2>
            </Col>
        </Row>
    </>
    );
}