import React from 'react';
import Radio from './pages/radio';

const App = () => {
  return(
    <div className="App">
      <header className="App-header">
        <div>
          <Radio/>
        </div>
      </header>
    </div>
  )
}

export default App;
